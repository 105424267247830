/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    img: "img",
    strong: "strong",
    h2: "h2"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "how-does-alcohol-affect-your-hearing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-does-alcohol-affect-your-hearing",
    "aria-label": "how does alcohol affect your hearing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How does alcohol affect your hearing?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-impaired-phone-hero.png",
    alt: "Soundwaves and ear background hero image",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Many Americans like a glass of wine or a few beers every now and then. In fact, around ", React.createElement(_components.strong, null, "24 million Americans take more than 70 drinks per week"), ", and only 30 percent of us don’t drink at all. But does drinking affect hearing, and what level of alcohol consumption is safe for those at risk from hearing loss? Well, as you’d expect with a medical and social issue like drinking, the picture is complex, but there are some takeaways for hearing loss patients to think about."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "does-alcohol-cause-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#does-alcohol-cause-hearing-loss",
    "aria-label": "does alcohol cause hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Does alcohol cause hearing loss?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You might have read somewhere that drinking can actually lead to hearing loss. While that’s an exaggeration in most cases (moderate drinking won’t usually leave you deaf), alcohol does have some well-documented effects on the human brain, and these can have an impact on peoples’ hearing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you drink regularly over long periods, ", React.createElement(_components.strong, null, "alcohol damages the part of your brain that processes auditory signals"), " (the central auditory cortex). This was studied by a team of researchers at Germany’s University of Ulm. When they measured the strength of “brainstem auditory evoked potentials” in people who recorded different alcohol intake levels, the results were, well, sobering."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The “latency” i.e. the speed of response, of ", React.createElement(_components.strong, null, "heavy drinkers was found to correlate with poor hearing"), ", indicating that drinking definitely does have dangerous consequences for the brainstem."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The question is ", React.createElement(_components.strong, null, "what amount of drinking is safe"), ", and what causes irreversible hearing loss. The Ulm researchers had nothing to say here, and we’re still a little unsure. Some say that regular alcohol intake has effects that “build up” over time, while others see heavy binge drinking as the culprit. But what’s certain is that alcohol and hearing loss do go together."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-about-the-ears-does-alcohol-affect-them-too",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-about-the-ears-does-alcohol-affect-them-too",
    "aria-label": "what about the ears does alcohol affect them too permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What about the ears? Does alcohol affect them too?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Other studies have looked in detail at these questions, and the results are interesting (and a little bit worrying if you drink a lot)."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Remember that your ears contain huge concentrations of very fine hairs, which create nervous impulses when they vibrate, and send these electrical signals to the brain. Research has found that ", React.createElement(_components.strong, null, "alcohol has an effect on these crucial hairs, and it’s this process which damages drinkers’ hearing.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "What seems to be happening is that drinking creates a ", React.createElement(_components.strong, null, "“toxic” environment for these hairs"), ", weakening them and causing them to fail. As they die off, they aren’t replaced by the body as with many types of hair. Instead, they just stop working and the individual’s hearing gradually degrades."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The effects appear to revolve more around low frequency hearing. You might have encountered this at busy bars, where after a few Daiquiris it has become harder to pick out conversations. There’s actually a term for this – “cocktail deafness” – which generally refers to temporary alcohol-induced deafness."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "However, we are coming to learn that cocktail deafness can be longer lasting and more damaging than just a few hours of poor hearing. Again, it’s not helpful to exaggerate, but sustained drinking does seem to damage ear hairs, and probably for good."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "drinking-leads-to-risky-behavior-which-is-bad-for-our-ears",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#drinking-leads-to-risky-behavior-which-is-bad-for-our-ears",
    "aria-label": "drinking leads to risky behavior which is bad for our ears permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Drinking leads to risky behavior, which is bad for our ears"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The damaging effects of alcohol on hearing aren’t confined to physical, biological processes. ", React.createElement(_components.strong, null, "When we drink to excess, the parts of our brain which help us calculate risks stop working properly."), " We do things we normally wouldn’t, and often this behavior can be bad for our ears."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "For instance, if you experience cocktail deafness in a loud bar, with booming music, are you likely to go outside or find a quiet corner, or continue your conversation? And, if you are in a nightclub or music venue, are you going to ration your exposure to loud noises? Probably not."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "And that’s before exposure to other risks, such as accidents arising from alcohol consumption."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "does-alcohol-cause-tinnitus",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#does-alcohol-cause-tinnitus",
    "aria-label": "does alcohol cause tinnitus permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Does alcohol cause tinnitus?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Tinnitus is another common complaint among heavy drinkers Although it’s not as serious as the other issues we’ve been talking about, it can be incredibly annoying."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The link between drinking and tinnitus (ringing in the ears) is fairly well established. When we drink, the blood vessels around our ears expand. As a consequence, more blood flows to our ears, and it’s this blood flow that causes fuzziness when we drink."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ironically, ", React.createElement(_components.strong, null, "many people who suffer from tinnitus respond to the irritation caused by the condition with increased alcohol consumption"), " – creating a harmful feedback loop. If this sounds like you, there are other solutions to the problem which are much less hazardous for your health, including specialist hearing aids, so don’t self-medicate. Get a consultation."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-to-drink-safely-and-minimize-the-risks-to-your-hearing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-drink-safely-and-minimize-the-risks-to-your-hearing",
    "aria-label": "how to drink safely and minimize the risks to your hearing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to drink safely and minimize the risks to your hearing"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Before you stop drinking entirely to protect your hearing, relax. 70 percent of Americans drink regularly, and 70 percent of Americans aren’t afflicted by hearing loss. The number is actually around 15 percent, ", React.createElement(_components.strong, null, "so clearly not everyone who drinks will ruin their hearing.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The issue is how to mix social drinking with safe behavior. The first step is to understand the risks involved, and to limit your exposure to excessive alcohol and noise at the same time. Excuse yourself after a few hours in noisy environments, and be aware of things like tinnitus and dizziness (another problem related to the inner ear)."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The important thing is to moderate your drinking, and seek professional help when issues arise. You definitely don’t need to stop drinking. In fact, some studies have found that a glass of red wine a day can protect your hearing – so don’t be puritanical. Take it easy, deal with problems when they arise, and you’ll be fine."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
